import moment from "@/plugins/moment";

export const formMutators = {
  querySelect(form, xPath) {
    const paths = xPath.split(".");
    const lastKey = paths.pop();
    const target = paths.reduce((acc, val) => {
      return acc[val];
    }, form);

    return { target, lastKey };
  },
  setDefault(form, values) {
    for (let xPath in values) {
      const value = values[xPath];
      const { target, lastKey } = this.querySelect(form, xPath);

      if (!target[lastKey]) {
        target[lastKey] = value;
      }
    }
    return this;
  },
  formatDates(form, values) {
    for (let xPath in values) {
      const value = values[xPath];
      const { target, lastKey } = this.querySelect(form, xPath);
      if (target[lastKey]) {
        target[lastKey] = moment(target[lastKey]).format(value || "DD.MM.YYYY");
      }
    }
    return form;
  },
  toIso(form, values) {
    for (let xPath in values) {
      const { target, lastKey } = this.querySelect(form, xPath);

      if (target[lastKey]) {
        target[lastKey] = moment(target[lastKey], "DD.MM.YYYY").format();
      }
    }

    return form;
  },
};
