import { DateValidator, RequiredValidator } from "./Base";

export const ClientValidation = {
  name: [RequiredValidator],
  surname: [RequiredValidator],
  lastname: [RequiredValidator],
  birthday: [RequiredValidator, DateValidator],
};

export const DriverValidation = {
  series: [RequiredValidator],
  number: [RequiredValidator],
  issued_by: [RequiredValidator],
  issue_date: [RequiredValidator, DateValidator],
  experience: [RequiredValidator],
};

export const PassportValidation = {
  series: [RequiredValidator],
  number: [RequiredValidator],
  issued_by: [RequiredValidator],
  issue_date: [RequiredValidator, DateValidator],
};
