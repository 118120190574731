import _ from "lodash";
import { formMutators } from "@/modules/client/formats/formMutators";

export function createForm(fields) {
  const form = _.cloneDeep(fields);
  return {
    form,
    setDefault(values) {
      formMutators.setDefault(form, values);
      return this;
    },
    formatDates(values) {
      formMutators.formatDates(form, values);
      return this;
    },
    toIso(values) {
      formMutators.toIso(form, values);
      return this;
    },
  };
}
