<template>
  <div>
    <VueFileAgent
        ref="licenceFileAgent"
        :uploadUrl="`${protocol}//${domain}/api/personal/media`"
        :uploadHeaders="{ ...getAuthHeader }"
        :multiple="true"
        :deletable="true"
        :editable="true"
        :meta="true"
        :accept="'image/*, .pdf'"
        :maxFiles="8"
        :linkable="true"
        :errorText="{
        type: 'Пожалуйста, выберите изображения или файлы PDF',
        size: 'Вы выбрали файл большего размера!',
      }"
        :help-text="label"
        :thumbnailSize="120"
        :theme="'list'"
        @delete="deleteFile($event)"
        @upload="onFileUploaded"
        v-model="fileRecords"
    ></VueFileAgent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MediaSelect",
  props: {
    value: {
      type: Array,
      required: true,
    },
    label: {
      default: "Выберите файлы",
    },
  },
  data: () => ({
    fileRecords: [],
    domain: process.env.VUE_APP_AXIOS_DOMAIN,
    protocol: window.location.protocol
  }),
  computed: {
    ...mapGetters("config", ["getAuthToken"]),
    ...mapGetters("media", ["allFiles"]),
    getAuthHeader() {
      return {
        Authorization: `Bearer ${this.getAuthToken}`,
      };
    },
  },
  methods: {
    ...mapActions("media", ["fetchAll"]),
    onFileUploaded(files) {
      const slice = this.value.slice();
      files.forEach((file) => {
        const { id } = file.data[0];
        slice.push(id);
      });

      this.$emit("input", slice);
    },
    deleteFile(file) {
      const { id } = file;
      const slice = this.value.filter((f) => +f !== +id);
      this.$emit("input", slice);
    },
  },
  async mounted() {
    await this.fetchAll();

    this.fileRecords = this.allFiles
      .filter((file) => this.value.includes(file.id))
      .map((file) => ({
        id: file.id,
        name: file.name + ".png",
        type: "image/jpeg",
        size: 0,
        ext: "jpg",
        url: file.path,
      }));
  },
};
</script>

<style scoped></style>
