<template>
  <div class="m-3">
    <div class="row g-3">
      <div class="col-12">
        <v-card>
          <drivers-list />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
// import ClientInfo from "@/modules/client/components/widgets/ClientInfo";
// import CurrentRent from "@/modules/client/components/widgets/CurrentRent";
import DriversList from "@/modules/client/components/widgets/DriversList";

export default {
  name: "ClientDrivers",
  components: { DriversList },
};
</script>

<style scoped></style>
