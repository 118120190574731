<template>
  <v-data-table
    v-model="selected"
    class="data-table"
    :headers="headers"
    :items="forms"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    :loading="loading"
    loading-text="Загрузка... Пожалуйста, подождите"
    :disable-sort="true"
    show-select
    show-expand
  >
    <template v-slot:top>
      <v-toolbar
        elevation="0"
        flat
      >
        <v-row align="center">
          <v-col cols="12">
            <h5>Водители</h5>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-toolbar>

      <div class="px-4">
        <v-row class="g-2">
          <v-col
            cols="12"
            md="auto"
          >
            <v-btn
              block
              color="primary"
              :loading="addLoading"
              @click="addDriver"
            >
              <v-icon
                left
                dark
              >mdi-plus</v-icon>
              Добавить водителя
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="auto"
          >
            <v-btn
              block
              color="primary"
              depressed
              :disabled="hasSelected"
              @click="removeDrivers"
            >
              <v-icon
                left
                dark
              >mdi-delete</v-icon>
              Удалить
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>

    <template v-slot:item.name="{ item }">
      {{ getFullName(item) }}
    </template>

    <template v-slot:item.passport="{ item }">
      {{ item.document.series }} {{ item.document.number }}
    </template>

    <template v-slot:item.licence="{ item }">
      {{ item.license.series }} {{ item.license.number }}
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip
        :class="item.status.code || ''"
        small
      >
        {{ item.status.name }}
      </v-chip>
    </template>

    <template v-slot:item.data-table-expand="{ expand, isExpanded }">
      <v-icon @click="expand(!isExpanded)" color="primary">mdi-note-edit-outline</v-icon>
    </template>

    <template v-slot:expanded-item="{ headers, item: driver }">
      <td :colspan="headers.length">
        <v-form v-model="valid">
          <fieldset :disabled="driver.id === user.main_driver.id">
            <v-row class="m-4">
              <v-col
                cols="12"
                sm="6"
                xl="4"
              >
                <v-text-field
                  label="Фамилия"
                  v-model="driver.surname"
                  :rules="validationRules.client.surname"
                  required
                ></v-text-field>

                <v-text-field
                  label="Имя"
                  required
                  v-model="driver.name"
                  :rules="validationRules.client.name"
                ></v-text-field>

                <v-text-field
                  label="Отчество"
                  required
                  v-model="driver.lastname"
                  :rules="validationRules.client.lastname"
                ></v-text-field>

                <v-text-field
                  label="Дата рождения"
                  v-model="driver.birthday"
                  :rules="validationRules.client.birthday"
                  v-maska
                  data-maska="##.##.####"
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                xl="4"
              >
                <v-row class="mt-0">
                  <v-col class="py-0">
                    <v-text-field
                      label="Документ"
                      value="Паспорт РФ"
                      readonly
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col class="py-0">
                    <v-text-field
                      label="Дата выдачи"
                      v-maska
                      data-maska="##.##.####"
                      v-model="driver.document.issue_date"
                      :rules="validationRules.passport.issue_date"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col class="py-0">
                    <v-text-field
                      label="Серия"
                      v-maska
                      data-maska="####"
                      required
                      v-model="driver.document.series"
                      :rules="validationRules.passport.series"
                    ></v-text-field>
                  </v-col>

                  <v-col class="py-0">
                    <v-text-field
                      label="Номер"
                      v-maska
                      data-maska="######"
                      required
                      v-model="driver.document.number"
                      :rules="validationRules.passport.number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="py-0">
                    <v-text-field
                      label="Кем выдан"
                      required
                      v-model="driver.document.issuedBy"
                      :rules="validationRules.passport.issued_by"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="py-0">
                    <MediaSelect
                      :key="driver.id"
                      label="Копии паспорта"
                      v-model="driver.document.photos"
                    >
                    </MediaSelect>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                xl="4"
              >
                <v-row class="mt-0">
                  <v-col class="py-0">
                    <v-text-field
                      v-model="driver.license.series"
                      :rules="validationRules.driver.series"
                      v-maska
                      data-maska="## ##"
                      label="Серия ВУ"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col class="py-0">
                    <v-text-field
                      label="Номер ВУ"
                      v-maska
                      data-maska="######"
                      v-model="driver.license.number"
                      :rules="validationRules.driver.number"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="py-0">
                    <v-text-field
                      label="Дата выдачи"
                      v-model="driver.license.issue_date"
                      :rules="validationRules.driver.issue_date"
                      v-maska
                      data-maska="##.##.####"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col class="py-0">
                    <v-text-field
                      v-model="driver.experience"
                      :rules="validationRules.driver.experience"
                      v-maska
                      data-maska="####"
                      label="Стаж (год)"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="py-0">
                    <v-text-field
                      label="Кем выдан"
                      :rules="validationRules.driver.issued_by"
                      v-model="driver.license.issuedBy"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="py-0">
                    <MediaSelect
                      :key="driver.id"
                      label="Копии ВУ"
                      v-model="driver.license.photos"
                    >
                    </MediaSelect>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  :rules="[RequiredValidator]"
                  v-model="driver.privacy"
                >
                  <template v-slot:label>
                    <div>
                      Данные водителя указаны с его согласия
                    </div>
                  </template>
                </v-checkbox>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                xl="4"
                v-if="driver.id !== user.main_driver.id"
              >
                <v-btn
                  color="primary"
                  :loading="saveLoading"
                  :disabled="!valid || driver.license.photos.length === 0 || driver.document.photos.length === 0"
                  @click="saveDriverItem(driver)"
                >
                  <v-icon
                    left
                    dark
                  >mdi-file-check-outline</v-icon>
                  Сохранить
                </v-btn>
              </v-col>
            </v-row>
          </fieldset>
        </v-form>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import _ from "lodash";

import {
  ClientValidation,
  DriverValidation,
  PassportValidation,
} from "../../validation/DriverValidation";
import MediaSelect from "@/modules/client/components/widgets/MediaSelect.vue";
import {createForm} from "@/modules/client/utils/createForm";
import {RequiredValidator} from "@/modules/client/validation/Base";

export default {
  name: "DriversList",
  components: {MediaSelect},
  data: () => ({
    loading: false,
    addLoading: false,
    saveLoading: false,
    expanded: [],
    selected: [],
    singleExpand: true,
    valid: false,
    forms: [],
    headers: [
      {
        text: "ФИО",
        align: "start",
        sortable: false,
        value: "name",
      },
      {text: "Паспорт", align: "start", value: "passport"},
      {text: "Водительское", align: "start", value: "licence"},
      {text: "Стаж", align: "start", value: "experience"},
      {text: "Статус", align: "start", value: "status"},
      {text: "", value: "data-table-expand"},
    ],
  }),
  methods: {
    RequiredValidator,
    ...mapActions("drivers", ["loadDrivers", "saveDriver", "deleteDrivers"]),
    ...mapMutations("drivers", ["createDriver"]),
    getFullName(person) {
      return (
          (person.surname ? person.surname : "Фамилия") +
          " " +
          (person.name ? _.first(person.name) + "." : "Имя") +
          " " +
          (person.lastname ? _.first(person.lastname) + "." : "Отчество")
      );
    },
    addDriver() {
      try {
        this.loading = true;
        this.addLoading = true;
        this.createDriver();
        this.forms.push(this.driversList[0]);
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
          this.addLoading = false;
        }, 1000);
      }
    },
    removeDrivers() {
      this.deleteDrivers(this.selected);
      this.selected = [];
    },
    saveDriverItem(driver) {
      this.loading = true;
      this.saveLoading = true;

      try {
        const form = createForm(driver).toIso({
          birthday: "",
          "document.issue_date": "",
          "license.issue_date": "",
        }).form;

        this.saveDriver({
          form: {
            driver: form,
            license: form.license,
            document: form.document,
          },
          id: driver.id,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
          this.saveLoading = false;
        }, 1000);
      }
    },
  },
  computed: {
    ...mapGetters("drivers", {
      driversList: "getDrivers",
    }),
    ...mapGetters("user", ["user"]),
    validationRules() {
      return {
        client: ClientValidation,
        driver: DriverValidation,
        passport: PassportValidation,
      };
    },
    hasSelected: function () {
      return _.isEmpty(this.selected);
    },
  },
  beforeMount() {
    this.loading = true;
  },
  async mounted() {
    await this.loadDrivers();
    this.loading = false;

    this.forms = this.driversList.map((driver) => {
      return createForm(driver)
          .setDefault({
            privacy: true,
            "document.photos": [],
            "license.photos": [],
          })
          .formatDates({
            birthday: "DD.MM.YYYY",
            "license.issue_date": "DD.MM.YYYY",
            "document.issue_date": "DD.MM.YYYY",
          }).form;
    });
  },
};
</script>

<style scoped lang="scss">
.data-table {
  &.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
  :deep(.v-data-table__expanded.v-data-table__expanded__row) {
    background: rgba($color: #ff6600, $alpha: 0.1);
  }

  .v-data-table__expand-icon,
  :deep(.v-simple-checkbox .v-icon) {
    color: #ff6600;
  }
}
</style>
